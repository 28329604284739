import { getFirestore, collection, query, where, orderBy, getDocs } from 'firebase/firestore'

export default {
  async fetchRedirects ({ commit }) {
    commit('setLoading', true)
    try {
      const result = await getRedirects()
      commit('setRedirects', result)
    } catch (error) {
      commit('setShowMessage', { show: true, color: 'error', text: `Fetch redirects failed: ${error}` })
    } finally {
      commit('setLoading', false)
    }
  },
  async pollRedirects ({ commit }) {
    try {
      const result = await getRedirects()
      commit('setRedirects', result)
    } catch (error) {
      commit('setShowMessage', { show: true, color: 'error', text: `Poll redirects failed: ${error}` })
    }
  }
}

async function getRedirects () {
  const intervalInMinutes = 5
  const currentPoint = getCurrentTimePoint(intervalInMinutes)
  const intervalInMillis = intervalInMinutes * 60 * 1000

  const pointsCounter = 24
  const points = new Array(pointsCounter)
  for (let i = 0; i < pointsCounter; i++) {
    points[pointsCounter - i - 1] = new Date(currentPoint - i * intervalInMillis)
  }

  const fromPoint = points[0].getTime() - intervalInMillis / 2

  const q = query(collection(getFirestore(), 'redirects'), where('created', '>=', fromPoint), orderBy('created', 'desc'))
  const redirects = await getDocs(q)

  // const data = Array.from({length: pointsCounter}, () => Math.floor(Math.random() * 1000))
  const data = new Array(pointsCounter).fill(0)
  redirects.forEach(doc => {
    const index = Math.floor((doc.data().created - fromPoint) / intervalInMillis)
    if (index >= 0 && index < pointsCounter) {
      data[index] = data[index] + 1
    }
  })

  const labels = points.map(date => format(date))
  return { labels, data }
}

function getCurrentTimePoint (min) {
  return new Date(Math.ceil(new Date().getTime() / 1000 / 60 / min) * min * 60 * 1000)
    .getTime()
}

function format (d) {
  return ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)
}
