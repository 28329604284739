<template>
  <div>
    <v-container>

      <v-layout row wrap>
        <v-flex xs12 md10 offset-md1 xl8 offset-xl2>
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loading"
          ></v-progress-circular>

          <LiveLine v-if="isAuthed && !loading && redirects"
            :chartdata="liveData" :options="options" />
        </v-flex>
      </v-layout>
    </v-container>

    <SignIn v-if="!isAuthed" />
  </div>
</template>

<script>
import 'firebase/app'
import { getAuth } from 'firebase/auth'
import SignIn from '../components/SignIn'
import LiveLine from '../components/LiveLine'
import Auth from '../mixin/auth'

export default {
  mixins: [Auth],
  components: {
    SignIn,
    LiveLine
  },
  data () {
    return {
      polling: null
    }
  },
  computed: {
    redirects: function () {
      return this.$store.getters.redirects
    },
    liveData: function () {
      return {
        labels: this.redirects.labels,
        datasets: [{
          data: this.redirects.data,
          label: 'Live Access',
          borderColor: '#1976d2',
          fill: true
        }]
      }
    },
    options: function () {
      let max = Math.floor(Math.max(...this.redirects.data) * 1.5)
      if (max < 10) {
        max = 10
      }
      return {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          yAxes: [{
            ticks: {
              min: 0, // minimum will be 0, unless there is a lower value.
              max,
              beginAtZero: true // minimum value will be 0.
            }
          }]
        },
        elements: {
          line: {
            tension: 0 // disables bezier curves
          }
        }
      }
    },
    loading: function () {
      return this.$store.getters.loading
    }
  },
  methods: {
    authedAction () {
      this.$store.dispatch('fetchRedirects')
      this.startPolling()
    },
    unauthedAction () { },
    startPolling () {
      this.polling = setInterval(() => {
        this.$store.dispatch('pollRedirects')
      }, 20 * 1000)
    }
  },
  watch: {
    '$route.query': function (newValue, oldValue) {
      console.log(newValue)
      const { from } = newValue
      if (from === 'signIn') {
        this._authAct(!!getAuth().currentUser)
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.polling)
  }
}
</script>
