import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
import router from '@/router'

export default {
  signInGoogle ({ commit }) {
    const provider = new GoogleAuthProvider()
    getAuth().useDeviceLanguage()
    signInWithPopup(getAuth(), provider).then(function (result) {
      console.log('signInWithPopup: ' + getAuth().currentUser.uid)
      router.push('/?from=signIn')
    }).catch(function (error) {
      console.error(error)
      commit('setShowMessage', { show: true, color: 'error', text: `Login failed: ${error}` })
    })
  },
  logout ({ commit }) {
    signOut(getAuth()).then(function () {
      commit('resetStore')
      router.push('/')
    }).catch(function (error) {
      commit('setShowMessage', { show: true, color: 'error', text: `Logout failed: ${error}` })
    })
  }
}
