<template>
  <canvas ref="chart" class="line-canvas"></canvas>
</template>

<script>
import Chart from 'chart.js'

export default {
  props: ['chartdata', 'options'],
  data () {
    return {
      chartInstance: null
    }
  },
  computed: {
    data: function () {
      return this.chartdata
    }
  },
  methods: {
    drawChart () {
      const animationOptions = { }
      if (this.chartInstance != null) {
        this.chartInstance.destroy()
        animationOptions['animation'] = {
          duration: 0
        }
      }

      const chart = this.$refs.chart
      const ctx = chart.getContext('2d')
      this.chartInstance = new Chart(ctx, {
        type: 'line',
        data: this.data,
        options: Object.assign(animationOptions, this.options)
      })
    }
  },
  mounted () {
    this.drawChart()
  },
  watch: {
    data: function () {
      this.drawChart()
    }
  }
}
</script>

<style>
.line-canvas {
  width: 100%;
  height: 400px;
}
</style>
