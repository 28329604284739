<template>
  <v-app app>
    <navigation />
    <ShowMessage />

    <v-content>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import Navigation from '@/components/Navigation'
import ShowMessage from '@/components/ShowMessage'

export default {
  name: 'App',
  components: {
    Navigation,
    ShowMessage
  },
  data () {
    return {
      //
    }
  }
}
</script>
