import { getFirestore, collection, doc, updateDoc, addDoc, deleteDoc, serverTimestamp } from 'firebase/firestore'
import common from './common'

export default {
  async saveLink ({ commit }, link) {
    try {
      const campaign = common.findById(this.getters.campaigns, link.campaignId)

      if (link.id) {
        const { tag, from, to } = link
        const updateFields = { tag, from, to }
        await updateDoc(doc(getFirestore(), 'campaignLinks', link.id), updateFields)

        const links = campaign.links
        const linkIndex = links.findIndex(l => l.id === link.id)
        const newLink = { ...links[linkIndex], ...updateFields }

        campaign.links = [...links.slice(0, linkIndex), newLink, ...links.slice(linkIndex + 1)]
      } else {
        const { campaignId, tag, from, to } = link
        const linkToSave = {
          campaignId,
          tag,
          from,
          to,
          created: serverTimestamp(),
          uri: `${Date.now()}${Math.floor(Math.random() * (1000 - 100 + 1)) + 100}`
        }
        const docRef = await addDoc(collection(getFirestore(), 'campaignLinks'), linkToSave)
        const newLink = { ...linkToSave, id: docRef.id, campaign: link.campaign, customer: link.customer, redirectHost: link.redirectHost }

        campaign.links = [newLink, ...campaign.links]
      }
    } catch (error) {
      console.log(error)
      commit('setShowMessage', { show: true, color: 'error', text: `Save link failed: ${error}` })
    }
  },
  async deleteLink  ({ commit }, link) {
    try {
      await deleteDoc(doc(getFirestore(), 'campaignLinks', link.id))

      const campaign = common.findById(this.getters.campaigns, link.campaignId)
      const links = campaign.links
      const linkIndex = links.findIndex(l => l.id === link.id)
      campaign.links = [...links.slice(0, linkIndex), ...links.slice(linkIndex + 1)]
    } catch (error) {
      commit('setShowMessage', { show: true, color: 'error', text: `Delete link failed: ${error}` })
    }
  }
}
