import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/main.css'
import '@/firebase/'
import Confirm from '@/components/Confirm'

Vue.config.productionTip = false

Vue.component('confirm', Confirm)

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: function () {
    this.$nextTick(function () {
      console.log('mounted from main')
    })
  }
}).$mount('#app')
