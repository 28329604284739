import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarSideIcon } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c(VToolbar,[_c(VToolbarSideIcon,{staticClass:"hidden-md-and-up",on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c(VSpacer,{staticClass:"hidden-md-and-up"}),_c(VToolbarTitle,{staticClass:"headline"},[_c('span',[_vm._v("SJ Media")]),_c('span',{staticClass:"font-weight-light"},[_vm._v(" Group")])]),_c(VSpacer),_c(VBtn,{staticClass:"hidden-sm-and-down",attrs:{"flat":"","to":"/"}},[_c(VIcon,[_vm._v("fa-home")])],1),_c(VBtn,{staticClass:"hidden-sm-and-down",attrs:{"flat":"","to":"/customers"}},[_c('span',{staticClass:"mr-2"},[_vm._v("Customers")]),_c(VIcon,[_vm._v("fa-users")])],1),_c(VBtn,{staticClass:"hidden-sm-and-down",attrs:{"flat":"","to":"/report"}},[_c('span',{staticClass:"mr-2"},[_vm._v("Report")]),_c(VIcon,[_vm._v("fa-chart-bar")])],1),(_vm.isAuthed)?_c(VBtn,{staticClass:"hidden-sm-and-down",attrs:{"flat":""},on:{"click":function($event){return _vm.logout()}}},[_c('span',{staticClass:"mr-2"},[_vm._v("Logout")]),_c(VIcon,[_vm._v("fa-sign-out-alt")])],1):_vm._e()],1),_c(VNavigationDrawer,{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,[_c(VListTile,{attrs:{"to":"/"}},[_c(VListTileAction,[_c(VIcon,[_vm._v("fa-home")])],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v("Home")])],1)],1)],1),_c(VList,[_c(VListTile,{attrs:{"to":"/customers"}},[_c(VListTileAction,[_c(VIcon,[_vm._v("fa-users")])],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v("Customers")])],1)],1)],1),_c(VList,[_c(VListTile,{attrs:{"to":"/report"}},[_c(VListTileAction,[_c(VIcon,[_vm._v("fa-chart-bar")])],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v("Report")])],1)],1)],1),(_vm.isAuthed)?_c(VList,[_c(VListTile,{on:{"click":function($event){return _vm.logout()}}},[_c(VListTileAction,[_c(VIcon,[_vm._v("fa-sign-out-alt")])],1),_c(VListTileContent,[_c(VListTileTitle,[_vm._v("Logout")])],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }