<template>
  <span>
    <v-toolbar>
      <v-toolbar-side-icon class="hidden-md-and-up" @click="drawer = !drawer"></v-toolbar-side-icon>
      <v-spacer class="hidden-md-and-up"></v-spacer>

      <v-toolbar-title class="headline">
        <span>SJ Media</span>
        <span class="font-weight-light"> Group</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn flat class="hidden-sm-and-down" to="/">
        <v-icon>fa-home</v-icon>
      </v-btn>
      <v-btn flat class="hidden-sm-and-down" to="/customers">
        <span class="mr-2">Customers</span><v-icon>fa-users</v-icon>
      </v-btn>
      <v-btn flat class="hidden-sm-and-down" to="/report">
        <span class="mr-2">Report</span><v-icon>fa-chart-bar</v-icon>
      </v-btn>
      <v-btn v-if="isAuthed" flat class="hidden-sm-and-down" @click="logout()">
        <span class="mr-2">Logout</span><v-icon>fa-sign-out-alt</v-icon>
      </v-btn>
    </v-toolbar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list>
        <v-list-tile to="/">
          <v-list-tile-action>
            <v-icon>fa-home</v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>Home</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-list>
        <v-list-tile to="/customers">
          <v-list-tile-action>
            <v-icon>fa-users</v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>Customers</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-list>
        <v-list-tile to="/report">
          <v-list-tile-action>
            <v-icon>fa-chart-bar</v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>Report</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-list v-if="isAuthed">
        <v-list-tile @click="logout()">
          <v-list-tile-action>
            <v-icon>fa-sign-out-alt</v-icon>
          </v-list-tile-action>

          <v-list-tile-content>
            <v-list-tile-title>Logout</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
  </span>
</template>

<script>
export default {
  data: () => ({
    drawer: false
  }),
  computed: {
    isAuthed () {
      return this.$store.getters.isAuthed
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
      this.$store.dispatch('showMessage', { color: 'info', text: 'logged out' })
    }
  }
}
</script>
