<template>
  <v-container class="text-xs-center">
    <v-btn large color="primary" @click="signInGoogle">
      <i class="fab fa-google fa-2x"></i>
      <span class="pl-2">Sign in with Google</span>
    </v-btn>
  </v-container>
</template>

<script>
export default {
  data: () => ({
  }),
  methods: {
    signInGoogle () {
      this.$store.dispatch('signInGoogle')
    }
  }
}
</script>
