<template>
  <v-snackbar top
    v-model="show"
    :color="color"
  >
    {{ text }}
    <v-btn small icon @click="show = false">
      <v-icon>fa-times-circle</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    show: {
      get: function () {
        return this.$store.getters.showMessage.show
      },
      set: function (newValue) {
        if (!newValue) {
          this.$store.dispatch('closeMessage')
        }
      }
    },
    color () {
      return this.$store.getters.showMessage.color
    },
    text () {
      return this.$store.getters.showMessage.text
    }
  }
}
</script>
