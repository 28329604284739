<template>
  <v-dialog v-model="value" persistent max-width="300">
    <v-card>
      <v-card-title class="headline">Please Confirm</v-card-title>
      <v-card-text>
        <div>{{ message }}</div>
        <div class="error--text" v-if="!!warningMessage">{{ warningMessage }}</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" flat @click="closeDialog(false)">Cancel</v-btn>
        <v-btn color="green darken-1" flat @click="closeDialog(true)">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [ 'message', 'warningMessage', 'value' ],
  methods: {
    closeDialog (confirmed) {
      this.$emit('input', false)
      this.$emit('dialog-closed', confirmed)
    }
  }
}
</script>
