import * as firebase from 'firebase/app'
import 'firebase/firestore'

const config = {
  apiKey: 'AIzaSyDCAbKHJDLtfXMqjHLDTK_dUtBgT3fhmgI',
  authDomain: 'sj-media.firebaseapp.com',
  databaseURL: 'https://sj-media.firebaseio.com',
  projectId: 'sj-media',
  storageBucket: 'sj-media.appspot.com',
  messagingSenderId: '687688998581',
  appId: '1:687688998581:web:6ba5e97b76612687'
}

firebase.initializeApp(config)
