import { getFirestore, serverTimestamp, collection, query, where, orderBy, doc, getDocs, getDoc, updateDoc, addDoc, writeBatch } from 'firebase/firestore'

import router from '@/router'
import common from './common'

export default {
  fetchCustomers ({ commit }) {
    commit('setLoading', true)

    const q = query(collection(getFirestore(), 'customers'), orderBy('enabled', 'desc'), orderBy('created', 'desc'))
    getDocs(q).then(function (snapshot) {
      const customers = []
      snapshot.forEach(function (doc) {
        customers.push(common.doc2customer(doc))
      })
      commit('setCustomers', customers)
    }).catch(function (error) {
      commit('setShowMessage', { show: true, color: 'error', text: `fetch customers failed: ${error}` })
    }).finally(function () {
      commit('setLoading', false)
    })
  },
  async fetchCustomer ({ commit }, customerId) {
    commit('setLoading', true)
    try {
      const docRef = doc(getFirestore(), 'customers', customerId)
      const customerDoc = await getDoc(docRef)
      const customer = common.doc2customer(customerDoc)
      commit('setCurrentCustomer', customer)

      const campaignDocs = await common.fetchCampaignsForCustomer(customerId)

      const campaigns = []
      const linkPromises = []
      campaignDocs.forEach(function (campaignDoc) {
        campaigns.push(common.doc2campaign(campaignDoc))

        const q = query(collection(getFirestore(), 'campaignLinks'), where('campaignId', '==', campaignDoc.id), orderBy('created', 'desc'))
        linkPromises.push(getDocs(q))
      })
      const linkDocsList = await Promise.all(linkPromises)
      for (let i = 0; i < linkDocsList.length; i++) {
        const linkDocs = linkDocsList[i]
        const campaign = campaigns[i]
        campaign.links = []
        linkDocs.forEach(function (linkDoc) {
          const link = common.doc2link(linkDoc)
          link.campaign = campaign.name
          link.customer = customer.name
          link.redirectHost = customer.redirectHost
          campaign.links.push(link)
        })
      }
      if (campaigns && campaigns.length > 0) {
        campaigns[0].show = true
      }
      commit('setCampaigns', campaigns)
    } catch (error) {
      console.log(error)
      commit('setShowMessage', { show: true, color: 'error', text: `Fetch customer detail failed: ${error}` })
    } finally {
      commit('setLoading', false)
    }
  },
  async enableCustomer ({ commit }, customer) {
    const message = `${customer.enabled ? 'Enable' : 'Disable'} customer "${customer.name}"`
    try {
      const enableValue = customer.enabled ? 1 : 0

      const db = getFirestore()
      const batch = writeBatch(db)
      batch.update(doc(db, 'customers', customer.id), {
        enabled: enableValue
      })

      const campaignDocs = await common.fetchCampaignsForCustomer(customer.id)
      campaignDocs.forEach(function (campaignDoc) {
        batch.update(doc(db, 'campaigns', campaignDoc.id), {
          enabled: enableValue
        })
      })

      await batch.commit()

      commit('setShowMessage', { show: true, color: 'success', text: `${message} successfully` })
    } catch (error) {
      console.log(error)
      commit('setShowMessage', { show: true, color: 'error', text: `${message} failed: ${error}` })
      customer.enabled = !customer.enabled
    }
  },
  updateCustomer ({ commit }, { customer, oldName }) {
    updateDoc(doc(getFirestore(), 'customers', customer.id), { name: customer.name })
      .then(function (snapshot) {
        commit('setShowMessage', { show: true, color: 'success', text: 'Update customer name successfully' })
      }).catch(function (error) {
        commit('setShowMessage', { show: true, color: 'error', text: `Update customer name failed: ${error}` })
        customer.name = oldName
      })
  },
  createCustomer ({ commit }, customer) {
    addDoc(collection(getFirestore(), 'customers'), {
      ...customer,
      campaignsCount: 0,
      created: serverTimestamp()
    }).then(function (docRef) {
      commit('setShowMessage', { show: true, color: 'success', text: `Create customer "${customer.name}" successfully` })
    }).catch(function (error) {
      commit('setShowMessage', { show: true, color: 'error', text: `Create customer "${customer.name}" failed: ${error}` })
    }).finally(function () {
      router.push('/customers')
    })
  }
}
