import { getFirestore, collection, query, doc, getDocs, getDoc, updateDoc, runTransaction, serverTimestamp } from 'firebase/firestore'
import common from './common'

export default {
  async saveCampaign ({ commit }, campaign) {
    try {
      const db = getFirestore()
      if (campaign.id) {
        const updateFields = { name: campaign.name }
        await updateDoc(doc(db, 'campaigns', campaign.id), updateFields)

        const theCampaign = common.findById(this.getters.campaigns, campaign.id)
        theCampaign.name = campaign.name
      } else {
        const currentCustomer = this.getters.currentCustomer
        const campaignToSave = {
          customerId: currentCustomer.id,
          name: campaign.name,
          enabled: 1,
          created: serverTimestamp()
        }

        const newCampaignRef = doc(collection(db, 'campaigns'))
        await runTransaction(db, async (transaction) => {
          transaction.update(
            doc(db, 'customers', currentCustomer.id),
            { campaignsCount: currentCustomer.campaignsCount + 1 }
          )
          transaction.set(newCampaignRef, campaignToSave)
        })

        currentCustomer.campaignsCount = currentCustomer.campaignsCount + 1
        const newCampaign = { ...campaignToSave, id: newCampaignRef.id, links: [], show: true }
        commit('setCampaigns', [newCampaign, ...this.getters.campaigns])
      }
    } catch (error) {
      commit('setShowMessage', { show: true, color: 'error', text: `Save campaign failed: ${error}` })
    }
  },
  async pauseCampaign ({ commit }, campaign) {
    const message = `${campaign.enabled ? 'Pause' : 'Resume'} campaign`
    try {
      await updateDoc(doc(getFirestore(), 'campaigns', campaign.id), {
        enabled: campaign.enabled ? 0 : 1
      })
      campaign.enabled = !campaign.enabled

      commit('setShowMessage', { show: true, color: 'success', text: `${message} successfully` })
    } catch (error) {
      commit('setShowMessage', { show: true, color: 'error', text: `${message} failed: ${error}` })
    }
  },
  async fetchCampaigns ({ commit }, customerId) {
    commit('setReportCampaigns', [])
    try {
      const campaignDocs = await common.fetchCampaignsForCustomer(customerId)
      const campaigns = []
      campaignDocs.forEach(function (campaignDoc) {
        campaigns.push(common.doc2campaign(campaignDoc))
      })
      commit('setReportCampaigns', campaigns)
    } catch (error) {
      commit('setShowMessage', { show: true, color: 'error', text: `Fetch campaigns failed: ${error}` })
    }
  },
  async fetchReport ({ commit }, campaignId) {
    commit('setLoading', true)
    try {
      const campaignDocRef = doc(getFirestore(), 'campaigns', campaignId)
      const reportDocs = await getDocs(query(collection(campaignDocRef, 'reports')))

      const reports = []
      reportDocs.forEach(function (reportDoc) {
        const report = { name: reportDoc.id, data: reportDoc.data() }
        reports.push(report)
      })
      commit('setReports', reports)
    } catch (error) {
      commit('setShowMessage', { show: true, color: 'error', text: `Fetch report failed: ${error}` })
    } finally {
      commit('setLoading', false)
    }
  },
  async fetchCampaign ({ commit }, campaignId) {
    commit('setCampaignInfo', null)
    try {
      const db = getFirestore()
      const campaignDoc = await getDoc(doc(db, 'campaigns', campaignId))
      const customerDoc = await getDoc(doc(db, 'customers', campaignDoc.data().customerId))
      const campaignInfo = {
        campaign: campaignDoc.data().name,
        customer: customerDoc.data().name
      }
      commit('setCampaignInfo', campaignInfo)
    } catch (error) {
      commit('setShowMessage', { show: true, color: 'error', text: `Fetch campaign info failed: ${error}` })
    }
  }
}
