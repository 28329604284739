import Vue from 'vue'
import Vuex from 'vuex'
import 'firebase/auth'
import 'firebase/firestore'
import AuthActions from './auth_actions'
import CustomerActions from './customer_actions'
import CampaignActions from './campaign_actions'
import LinkActions from './link_actions'
import RedirectActions from './redirect_actions'

Vue.use(Vuex)

const initState = function () {
  return {
    isAuthed: false,
    loading: false,

    customers: [],
    campaigns: [],
    currentCustomer: null,

    redirects: null,

    reportCampaigns: [],
    reports: [],
    campaignInfo: null,

    showMessage: {
      show: false,
      color: 'info',
      text: ''
    }
  }
}

export default new Vuex.Store({
  state: initState(),
  getters: {
    showMessage (state) {
      return state.showMessage
    },
    isAuthed (state) {
      return state.isAuthed
    },
    customers (state) {
      return state.customers
    },
    currentCustomer (state) {
      return state.currentCustomer
    },
    redirects (state) {
      return state.redirects
    },
    campaigns (state) {
      return state.campaigns
    },
    reportCampaigns (state) {
      return state.reportCampaigns
    },
    reports (state) {
      return state.reports
    },
    campaignInfo (state) {
      return state.campaignInfo
    },
    loading (state) {
      return state.loading
    }
  },
  mutations: {
    setAuthed (state, payload) {
      state.isAuthed = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
    setCustomers (state, payload) {
      state.customers = payload
    },
    resetCustomers (state, payload) {
      state.customers = []
    },
    resetCustomer (state, payload) {
      state.campaigns = []
      state.currentCustomer = null
    },
    resetReports (state, payload) {
      state.reports = []
    },
    setCurrentCustomer (state, payload) {
      state.currentCustomer = payload
    },
    setRedirects (state, payload) {
      state.redirects = payload
    },
    setCampaigns (state, payload) {
      state.campaigns = payload
    },
    setReportCampaigns (state, payload) {
      state.reportCampaigns = payload
    },
    setReports (state, payload) {
      state.reports = payload
    },
    setCampaignInfo (state, payload) {
      state.campaignInfo = payload
    },
    setShowMessage (state, payload) {
      state.showMessage = payload
    },
    resetStore (state) {
      Object.assign(state, initState())
    }
  },
  actions: {
    ...AuthActions,
    ...CustomerActions,
    ...CampaignActions,
    ...LinkActions,
    ...RedirectActions,
    showMessage ({ commit }, { color, text }) {
      commit('setShowMessage', { show: true, color, text })
    },
    closeMessage ({ commit }) {
      commit('setShowMessage', { show: false, color: 'info', text: '' })
    }
  }
})
