import { getFirestore, collection, query, where, orderBy, getDocs } from 'firebase/firestore'

export default {
  doc2customer (doc) {
    const id = doc.id
    const data = doc.data()

    if (!data) throw new Error('Customer not found')

    const name = data.name
    const enabled = data.enabled === 1
    const campaignsCount = data.campaignsCount || 0
    const redirectHost = data.redirectHost || 'connect.sjmediagroup.com.au'
    return { id, name, enabled, campaignsCount, redirectHost }
  },
  doc2campaign (doc) {
    const id = doc.id
    const data = doc.data()

    if (!data) throw new Error('Campaign not found')

    const enabled = data.enabled === 1
    const { name, created } = data
    return { id, name, created, enabled, links: [], show: false }
  },
  doc2link (doc) {
    const id = doc.id
    const data = doc.data()

    if (!data) throw new Error('Link not found')

    const { tag, from, to, created, campaignId, uri } = doc.data()
    return { id, tag, from, to, created, campaignId, uri }
  },
  findById (list, id) {
    const index = list.findIndex(e => e.id === id)
    return list[index]
  },
  fetchCampaignsForCustomer (customerId) {
    const q = query(
      collection(getFirestore(), 'campaigns'),
      where('customerId', '==', customerId),
      orderBy('enabled', 'desc'),
      orderBy('created', 'desc')
    )
    return getDocs(q)
  }
}
