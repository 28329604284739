import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Please Confirm")]),_c(VCardText,[_c('div',[_vm._v(_vm._s(_vm.message))]),(!!_vm.warningMessage)?_c('div',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.warningMessage))]):_vm._e()]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","flat":""},on:{"click":function($event){return _vm.closeDialog(false)}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"green darken-1","flat":""},on:{"click":function($event){return _vm.closeDialog(true)}}},[_vm._v("OK")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }