import { getAuth } from 'firebase/auth'

export default {
  computed: {
    isAuthed: function () {
      return this.$store.getters.isAuthed
    }
  },
  methods: {
    _checkAuth () {
      console.log('auth mixins: _checkAuth')
      return new Promise(resolve => {
        if (getAuth().currentUser) {
          resolve(true)
        } else {
          const unsubscribe = getAuth().onAuthStateChanged(function (user) {
            console.log(`onAuthStateChanged => ${user}`)
            unsubscribe()
            if (user) {
              resolve(true)
            } else {
              resolve(false)
            }
          })
        }
      })
    },
    _authAct (auth) {
      this.$store.commit('setAuthed', auth)
      if (auth) {
        this.authedAction && this.authedAction()
      } else {
        (this.unauthedAction || this._unauthedAction)()
      }
    },
    _unauthedAction () {
      this.$router.push('/')
      this.$store.dispatch('showMessage', { color: 'info', text: 'Please sign in' })
    }
  },
  mounted () {
    console.log('auth mixins: mounted: ' + this)
    this._checkAuth().then(this._authAct)
  }
}
